import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthenticationService } from '../_services'



@Injectable({ providedIn: 'root' })
export class UnAuthGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log(this.authService.currentUserValue)
        if(this.authService.isLoggedIn()) {
            // check if route is restricted by role
            // if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
            //     // role not authorised so redirect to user page
            //     this.router.navigate(['/user'])
            //     return false
            // }

            // authorized so return true
            return true
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { redirect: state.url } })
        return false
    }
}