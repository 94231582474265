import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { HiveAPIService } from '../_api-services'
import { Observable, catchError, map, of, switchMap } from 'rxjs'



@Injectable({ providedIn: 'root' })
export class HiveAuthGuard {
    constructor(
        private router: Router,
        private hiveAPIService: HiveAPIService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const hiveId = route.parent?.params['handle']
        const previousUrl = state.url
        
        return this.hiveAPIService.getHiveByHandle(hiveId).pipe(
            switchMap(hive => {
                if (hive.type == 'public') {
                    return of(true)
                }
                
                return this.hiveAPIService.isUserInHive(hiveId).pipe(
                    map(inHive => {
                        if (!inHive) {
                            this.router.navigate([previousUrl])
                            return false
                        }
                        return true
                    }),
                    catchError(() => {
                        this.router.navigate([previousUrl])
                        return of(false)
                    })
                )
            }),
            catchError((e) => {
                console.log(e)
                // this.router.navigate([previousUrl])
                return of(false)
            })
        )
    }
}