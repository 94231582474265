<div class="hub-container hives-container">
    <div class="head-container">
        <div>
            <div class="flex space-between title">
                <h1>Hives</h1>
                <div class="flex btn btn-hub btn-skinny-rounded" (click)="add(modalContent)">
                    <img class="plus-icon" src="/assets/icon-plus.svg" alt="plus icon">
                    <p>Create</p>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="page-content">
    <div class="container" *ngIf="userIsLoggedIn">
        
        <section *ngIf="userHives && userHives.length">
            <div class="my-hives">
                <h2>Your Hives</h2>
                <div *ngIf="hivesExpanded">
                    <hive-card *ngFor="let hive of userHives" [hive]="hive" [type]="'activity'"></hive-card>
                </div>
                <div *ngIf="!hivesExpanded">
                    <hive-card *ngFor="let hive of userHives.slice(0,5)" [hive]="hive" [type]="'activity'"></hive-card>
                    <div *ngIf="userHives.length > 5" class="see-more" (click)="revealHives()">
                        <div class="btn btn-alternative btn-rounded">See all</div>
                    </div>
                </div>
            </div>
        </section>
        
        <section *ngIf="hives && hives.length">
            <h2>Discover More Hives</h2>
            <div class="hives">
                <hive-card *ngFor="let hive of hives" [hive]="hive"></hive-card>
            </div>
        </section>
    </div>

    <section class="container" *ngIf="!userIsLoggedIn">
        <h2>Discover Hives</h2>
        <div class="hives" *ngIf="hives">
            <hive-card *ngFor="let hive of hives" [hive]="hive"></hive-card>
        </div>
    </section>

    <ng-template #modalContent let-data="data">
        <create-hive-container *ngIf="showCreateHive"></create-hive-container>
    </ng-template>
</div>