import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root',
})
export class RegistrationGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService) {}

    canActivate(): boolean | UrlTree {
        if(!this.authService.isLoggedIn()) {
            return true
        }
        
        
        if (this.authService.isRegistrationComplete()) {
            return true
        } else {
            return this.router.parseUrl('/register')
        }
    }
}