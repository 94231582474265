import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthenticationService } from '../_services'



@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        let currentUser = this.authService.currentUserValue
        console.log(currentUser)
        if(currentUser.role != 'app') {
            // check if route is restricted by role
            // if (route.data['roles'] && route.data['roles'].indexOf(currentUser.role) === -1) {
            //     // role not authorised so redirect to user page
            //     this.router.navigate(['/user'])
            //     return false
            // }

            // User is logged in so return true
            return true
        }
        
        
        return false
    }
}